const constants = {};

constants.DEFAULT_URL = process.env.REACT_APP_BASE_URL;
constants.GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
constants.API_KEY = process.env.REACT_APP_API_KEY;
constants.API_KEY_GENERAL = process.env.REACT_APP_API_KEY_GENERAL;
constants.PRODUCT_TEMAS = "polismall-cargo-temas";
constants.PRODUCT_PALISADE = "polismall-cargo-meratus-paliside";
constants.PRODUCT_CARGO_BUNDLE = "polismall-cargo-bundle";
constants.PRODUCT_CARGO = "polismall-cargo";

export default constants;
